.App {
  height: 100vh;
  width: 100vw;
  max-width: 100vw;
  overflow-x:hidden;
  background-color: #ffffff;
  font-family: 'Space Grotesk', sans-serif;

  .header {
    height: 8%;
    max-height: 150px;
    
    @media (max-width: 650px) {
      min-height: 75px;
    }
  
    @media (min-width: 651px) {
      min-height: 100px;
    }

    img {
      margin-top: 1em;
      margin-left: 3vw;
      height: 100%;
    }

    .row {
      height: 100%
    }

    #nav {

      justify-content: right;

      .align-right {
        text-align: right;
        list-style: none; /* Optional: Removes default bullet points */
        margin: 0;
        padding: 0;

        
      @media (max-width: 765px) {
        display: none;
      }
    
      @media (min-width: 766px) {
        display: unset;
      }

      }

      .about {
        @media (max-width: 2500px) {
          margin-top: 3.5vw;
        }
      
        @media (min-width: 2501px) {
          margin-top: 2.5vw;
        }
      }

      ul {
        list-style: none;
        position: relative;
      }
       
      a {
        text-decoration: none;
        color: rgb(0, 0, 0);
        display: block;
      }
      
      li:nth-child(-n + 2) {
        margin-right: 6vw;
      }

      li {
    
        font-size: 2em;
        font-weight: 300;
        display: inline-block;
        margin-right: 2vw;
      }

      
    }

    .at-sign {
      opacity: 0.5;
    }
    .at-sign:hover {
      opacity: 1;
    }
  }

  .hero{
    align-content: center;
    justify-content: center;
    min-height: 60vh;
    
    .heroText{
      padding-top: 20vh;
      text-align: center;
      font-weight: 700;
      font-size: 5vw;

      @media (min-width: 1921px) {
        font-size: 8vw;
      }
    
      @media (min-width: 1001px) and (max-width: 1920px) {
        font-size: 10vw;
      }
    
      @media (min-width: 651px) and (max-width: 1000px) {
        font-size: 13vw;
      }
    
      @media (min-width: 491px) and (max-width: 650px) {
        font-size: 4.4em;
      }
    
      @media (min-width: 391px) and (max-width: 490px) {
        font-size: 3.5em;
      }
    
      @media (max-width: 390px) {
        font-size: 3em;
      }
    }
    
    .heroDescription{
      text-align: center;
      font-weight: 500;
      font-size: 1vw;

      @media (min-width: 1921px) {
        font-size: 2vw;
      }
    
      @media (min-width: 1001px) and (max-width: 1920px) {
        font-size: 3vw;
      }
    
      @media (min-width: 651px) and (max-width: 1000px) {
        font-size: 4vw;
      }
    
      @media (max-width: 650px) {
        font-size: 4vw;
      }

    }

    
  }

  .main{
    margin-left: 10vw;
    margin-right: 10vw; 

    .aboutMe{
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      max-width: 900px;
      margin-top: 5vw;
      font-size: 1.1rem;
    }

    .projects{
      margin-top: 5vw;
      
      h1 {
        text-align: center;
        margin-bottom: 29px;
      }

      .project {
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 2vh;


        img { 
          max-height: 460px;
          display: block;
          margin-left: auto;
          margin-right: auto;
          border-radius: 5px;
        }



        @media only screen and (max-width: 884px) {
          img {
            width: 100%;
            border-top-left-radius: 30px;
            border-top-right-radius: 30px;
          }
        }

        @media only screen and (min-width: 885px) {
          img {
            width: 75vw;
            max-width: 900px;
          }
        }

        .projectDetailContainer {
          display: block;
          margin-left: auto;
          margin-right: auto;
          min-height: 346px;
          max-width: 691px;
          position: relative;

          @media only screen and (max-width: 884px) {
            .card {
              border-top-left-radius: 0px;
              border-top-right-radius: 0px;
              border-radius: 0px 0px 30px 30px;
              top: -20px
            }
          }

          @media only screen and (min-width: 885px) {
            .card {
              border-radius: 30px;
              top: -90px;
            }
          }

          .card {
            position:absolute;
            width: 100%;
            height: 100%;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            padding: 25px;

            .projectName {
              text-align: center;
              font-weight: 700;
              font-size: 300%;
              margin-bottom: min(1.5vw, 17px);
            }

            .projectDetails {
              text-align: center;
              font-weight: 300;
              font-size: 0.9rem;
              max-height: 150px;
              min-height: 100px;
              overflow-y: scroll;
            }

            .projectDetails::-webkit-scrollbar {
              width: 0;
              height: 0;
            }
            

            .projectTools {
              text-align: center;
              font-weight: 300;
              font-size: 32px;
              letter-spacing: 25%;
            }

            .projectLink {
              border: #ffffff;
              min-height: 30px;
              min-width: 125px;
              border-radius: 11px;
              color: #ffffff;
              background-color: #D9D9D9;
              font-weight: 500;
              font-size: 16px;
            }

            @media only screen and (max-width: 900px) {
              .projectLink {
                min-width: 30%;
              }
            }
            
          }
        }
      }

    }

    .contact{
      margin-left:auto;
      margin-right: auto;
      width: max(75vw, 200px);
      max-width: 900px;
      text-align: center;
      margin-bottom: 10vw;

      a {
        color: #D9D9D9;
        font-size: 5rem;
        margin-right: 1vw;
        margin-left: 1vw;
        &:hover{
          color: #777777;
        }
      }

    }

  }
  
  .sticky_bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    margin-right: 7vw;
    margin-bottom: 3vw;
    width: 100%;
    color: rgb(0, 0, 0);
    text-align: right;
    font-weight: 400;
    font-size: 3em;
    opacity: 0.5;

    a {
      text-decoration: none;
      color: rgb(0, 0, 0);
    }

    @media (min-width: 766px) {
      display: none;
    }
  
    @media (max-width: 765pxpx) {
      diplay: unset;
    }
  }

  .sticky_bottom:hover{
    opacity: 1;
  }

  .footer{
    margin-left: auto;
    margin-right:auto;
    display:block;
    text-align: center;
    margin-bottom: 2vw;
  }
}

#weddingPage {
  width: 100vw;
  height: 100vh;  
  max-height: -webkit-fill-available;
  max-width: 100vw;

  overflow-y:hidden;
  background-color: #F1EDE7;
  font-family: 'Cormorant', serif;
  position: relative; /* Make sure parent has position set */

  .texture {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    z-index: 1;
    opacity: 15%;
    mix-blend-mode: multiply;
    pointer-events: none;
  }

  #headerImageContainer{
    margin-top: 7vh;
    margin-bottom: 5vh;
  }

  #headerImage {
    width: 100%;
    max-width: 650px;
    align-self: center;
  }

  @media only screen and (min-width: 1400px) {
    /* CSS for viewport more than 1400px wide */
    h1 {
      // font-size: 7.5vw;
      font-size: 105px;
  
      font-weight: 700;
      margin-bottom: 0.25vh;
      text-align: center;
    }

    h2{
      // font-size: 3.5vw;
      font-size: 30px;
      text-align: center;
      font-weight: 500;
    }

    h3{
      // font-size: 3.5vw;
      font-size: 50px;
      text-align: center;
      font-weight: 500;
    }


    #weddingFooter{
      max-width: 500px;
      width: 65vw;
      position: fixed;
      right: 0px; bottom: 0px;
    }
  }
  
  @media only screen and (max-width: 1400px) {
    /* CSS for viewport less than or equal to 1400px wide */
    h1 {
      font-size: 7.5vw;
      // font-size: 100px;
      font-weight: 700;
      margin-bottom: 0.25vh;
      text-align: center;
    }

    h2{
      font-size: 4vw;
      text-align: center;
      font-weight: 500;
    }

    h3{
      font-size: 3.5vw;
      text-align: center;
      font-weight: 500;
    }


    #weddingFooter{
      max-width: 500px;
      width: 45vw;
      position: absolute; /* Use absolute positioning */
      right: 0px; bottom: 0px; /* Position relative to parent */
    }
  }

  @media only screen and (max-height: 800px) and (min-width: 600px){
    /* CSS for viewport more than 1400px wide */
    #weddingFooter{
      max-width: 500px;
      width: 65vw;
      position: relative;
      float: right;
    }
  }
  

  #submitForm{
    margin-top: 5vh;
    margin-bottom: 2vh;

    #uploadButton {
      margin-left: 2vw;
      width: 60vw;
      height: 2.5em;
      border: 1px dotted black;
      padding: 0;
      outline: none;
      background-color: #F1EDE7;
      color: black;
      font-weight: 300;
      letter-spacing: .4em;
      position: relative;

      .progressBar {  
        width: 66%; 
        height: 100%;
        background-color: #cebca0;
        transition: all 500ms ease-out;
      }
    }

    #uploadButton:disabled {
      opacity: 0.3;
    }
  }

}

